import dayjs from 'dayjs'
import i18next from 'i18next'
import { ImageLoaderProps } from 'next/image'
import router from 'next/router'
import store from 'store'
import apiSlice from 'store/api.slice'
import { DialogType } from 'store/dialogs.slice'
import { IShowToastPayload, toastActions } from 'store/toast.slice'
import { IRegion, ITask, ITimer, IVars, ShareObjectType, TaskType, TopLevelDomainType } from 'types'
import { updatePersistentDialog } from './persistent'
import { isPC, micrositeNavigate } from './webBridge'

export const convertAppPath = (url: string): string => {
  const base64HomeUrl = window.btoa(
    JSON.stringify({
      paths: [{ webNav: { url } }],
    }),
  )
  return `home?navRoute=${window.encodeURIComponent(base64HomeUrl)}`
}

export const convertAppRL = (url: string) => `home?apprl=${encodeURIComponent(url)}`

export const getCookie = (name: string): string | null => {
  if (typeof document === 'undefined') return null
  return (
    document?.cookie
      .split('; ')
      .find((row) => row.startsWith(`${name}=`))
      ?.split('=')[1] || null
  )
}

export const requestTimeout = (fn: () => void, delay: number) => {
  const start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) fn()
      else rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const requestInterval = (fn: () => void, delay: number) => {
  let start = new Date().getTime()
  let rafID: number
  let isLoop = true
  const loop = () => {
    if (isLoop) {
      const current = new Date().getTime()
      const delta = current - start
      if (delta >= delay) {
        fn()
        start = new Date().getTime()
      }
      rafID = requestAnimationFrame(loop)
    }
  }
  rafID = requestAnimationFrame(loop)
  return {
    id: rafID,
    clear: () => {
      cancelAnimationFrame(rafID)
      isLoop = false
    },
  }
}

export const getTopLevelDomain = (hostname: Location['hostname']): TopLevelDomainType => {
  if (!hostname) return 'vn'
  const parts: string[] = hostname.split('.')
  const shopeePartIndex = parts.findIndex((e) => e === 'shopee')

  if (shopeePartIndex < 0) return 'vn'
  return parts.slice(shopeePartIndex + 1).join('.') as TopLevelDomainType
}

export const getLanguage = () =>
  ({
    vn: 'vi-VN',
    sg: 'en-SG',
    'com.my': 'en-MY',
    'co.th': 'th-TH',
    tw: 'zh-TW',
    'com.br': 'pt-BR',
    'co.id': 'id-ID',
  }[getTopLevelDomain(global?.location?.hostname)])

export const getDateFormat = () =>
  ({
    vn: 'DD/MM/YYYY',
    sg: 'DD/MM/YYYY',
    'com.my': 'DD/MM/YYYY',
    'co.th': 'DD/MM/YYYY',
    tw: 'YYYY/MM/DD',
    'com.br': 'DD/MM/YYYY',
    'co.id': 'MM/DD/YYYY',
  }[getTopLevelDomain(global?.location?.hostname)])

export const getVariables = () => {
  if (typeof window === 'undefined') return {} as IVars

  const topLevelDomain = getTopLevelDomain(global?.location?.hostname)

  const envVariables = {
    dev: {
      baseURL: `https://local-hoidap.giaitri.uat.shopee.${topLevelDomain}`,
      apiURL: `https://test-api.giaitri.uat.shopee.${topLevelDomain}`,
      universalShareURL: `https://uat.shopee.${topLevelDomain}/universal-link/quiz-share-local?deep_and_deferred=1`,
    },
    test: {
      baseURL: `https://test-quiz.giaitri.uat.shopee.${topLevelDomain}`,
      apiURL: `https://test-api.giaitri.uat.shopee.${topLevelDomain}`,
      universalShareURL: `https://uat.shopee.${topLevelDomain}/universal-link/quiz-share-test?deep_and_deferred=1`,
    },
    uat: {
      baseURL: `https://quiz.giaitri.uat.shopee.${topLevelDomain}`,
      apiURL: `https://api.giaitri.uat.shopee.${topLevelDomain}`,
      universalShareURL: `https://uat.shopee.${topLevelDomain}/universal-link/quiz-share-uat?deep_and_deferred=1`,
    },
    live: {
      baseURL: `https://quiz.giaitri.shopee.${topLevelDomain}`,
      apiURL: `https://api.giaitri.shopee.${topLevelDomain}`,
      universalShareURL: `https://shopee.${topLevelDomain}/universal-link/quiz-share?deep_and_deferred=1`,
    },
  }[process.env.ENV_ARG || 'dev']

  const additionalVariables = {
    nonProduction: {
      micrositePrefix: `https://uat.shopee.${topLevelDomain}/m`,
      universalMicrositePrefix: `https://uat.shopee.${topLevelDomain}/universal-link/m`,
      shopeeLoginURL: `https://uat.shopee.${topLevelDomain}/buyer/login`,
      shopeeURL: `https://uat.shopee.${topLevelDomain}`,
    },
    production: {
      micrositePrefix: `https://shopee.${topLevelDomain}/m`,
      universalMicrositePrefix: `https://shopee.${topLevelDomain}/universal-link/m`,
      shopeeLoginURL: `https://shopee.${topLevelDomain}/buyer/login`,
      shopeeURL: `https://shopee.${topLevelDomain}`,
    },
  }[process.env.ENV_ARG === 'live' ? 'production' : 'nonProduction']

  return { ...envVariables, ...additionalVariables } as IVars
}

export const getRegion = () =>
  getTopLevelDomain(global?.location?.hostname ?? '')
    .split('.')
    .slice(-1)
    .toString() as IRegion

export const getShareLink = (objectType: ShareObjectType) => {
  const { cid, site } = router.query
  const { baseURL, shopeeURL } = getVariables()

  //* use document.referrer to check that we are in web or app
  const from = document.referrer ? 'web' : 'app'
  const event = store.getState().app.selectingEvent

  return `${shopeeURL}/universal-link?redir=${encodeURIComponent(
    `${baseURL}/share?cid=${cid}&event=${event}&site=${site}&object_type=${objectType}&from=${from}`,
  )}&deep_and_deferred=1`
}

export const getImage = (image: string) => {
  const { baseURL } = getVariables()
  return `${baseURL}/images/${image}`
}

export const handleShareNavigation = (objectType: ShareObjectType) => {
  //* persist to show the task dialog when back from other pages
  const { baseURL } = getVariables()
  const questionIdx = store.getState().app.currentQuestion
  if (objectType === 'event') updatePersistentDialog(DialogType.TaskDialog, questionIdx)

  if (isPC()) micrositeNavigate(`${baseURL}/pending/`)
  else micrositeNavigate(getShareLink(objectType))
  console.log(getShareLink(objectType))
}

export const getMicrositeUrl = (site: string, tag?: string) => {
  const { micrositePrefix } = getVariables()
  return `${micrositePrefix}/${site}${tag ? `#${tag}` : ''}`
}

export const getMicrositeUniversalUrl = (site: string) => {
  const { universalMicrositePrefix } = getVariables()
  return `${universalMicrositePrefix}/${site}?deep_and_deffered=1`
}

export const getShopeeShopUrl = (shopId: number) => {
  const { shopeeURL } = getVariables()
  return `${shopeeURL}/shop/${shopId}`
}

export const getShopeeItemUrl = (shopId: number, itemId: number) => {
  const { shopeeURL } = getVariables()
  return `${shopeeURL}/product/${shopId}/${itemId}`
}

export const handleRequestError = (err) => {
  // The error is already handled
  if (err?.code) return { status: err.code, data: err }

  // The request don't have the response
  if (!err?.response && err.request) {
    return { status: 403, data: { code: 403, msg: err?.message } }
  }

  // The error don't have the response, mean it not Request error
  if (!err?.response) {
    return { status: 500, data: { code: 500, msg: err?.message || 'Unknown error' } }
  }

  // The formatted error return by Backend
  if (err.response.data?.code) return { status: err.response.status, data: err.response.data }

  // The response error that not handled by Backend
  return {
    status: err.response.status,
    data: { code: err.response.status, msg: err.response.statusText, data: err.response.data },
  }
}

export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat('vi-VN', {
    currency: 'VND',
    style: 'currency',
  })
  return formatter.format(+value)
}

export const numberFormatter = (value: number) => {
  return new Intl.NumberFormat('vi-VN', {}).format(value)
}

// This function to check data integrity does not care about security
export const simpleHash = (message: string) => {
  if (message.length === 0) return 0
  return message.split('').reduce((acc, c) => +((acc << 5) - acc + c.charCodeAt(0)), 0)
}

export const sha1Hash = async (message: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(message)
  return await window.crypto.subtle.digest('SHA-1', data)
}

export const randomID = (): string => `_${Math.random().toString(36).substr(2, 9)}`

export const checkIsLogin = (): boolean => {
  const userId = getCookie('SPC_U')
  return userId !== null && userId !== '-'
}

export const removeItemByValue = (arr: Array<string | number>, value: string | number) => {
  arr.forEach((e: string | number, i: number) => {
    if (e === value) arr.splice(i, 1)
  })

  return arr
}

export const getRandomNumber = (min: number, max: number) => {
  return Math.random() * (max - min) + min
}

export const getEventTabsLabel = (beginTime: number, endTime: number) => {
  if (!beginTime || !endTime) return i18next.t('Chưa có sự kiện')
  if (endTime - Date.now() <= 0) return i18next.t('Đã kết thúc')
  if (beginTime - Date.now() <= 0) return i18next.t('Đang diễn ra')

  const daysDiff = dayjs(beginTime).dayOfYear() - dayjs().dayOfYear()
  if (daysDiff === 1) return i18next.t('Ngày mai')
  if (daysDiff === 2) return i18next.t('Ngày mốt')
  if (daysDiff > 2) return dayjs(beginTime).format(getDateFormat())

  return i18next.t('Sắp diễn ra')
}

export const getDayLabel = (beginTime: number) => {
  const daysDiff = dayjs(beginTime).dayOfYear() - dayjs().dayOfYear()

  if (daysDiff === 0) return i18next.t('Hôm nay')
  if (daysDiff === 1) return i18next.t('Ngày mai')
  if (daysDiff === 2) return i18next.t('Ngày mốt')

  return dayjs(beginTime).format(getDateFormat())
}

// export const toCamelCase = (object) => {
//   if (typeof object !== 'object') return object

//   return Object.keys(object).reduce((obj, key) => {
//     const camalCaseKey = key.replace(/([-_][a-z])/g, (group) => group.slice(-1).toUpperCase())
//     obj[camalCaseKey] = object[key]

//     if (typeof object[key] === 'object') {
//       obj[camalCaseKey] = toCamelCase(object[key])
//     }

//     return obj
//   }, {})
// }

export const parseTextWithColor = (str: string) => {
  const textColorArr: [string, [string]] = JSON.parse(str)
  const [text, [color]] = textColorArr
  return { text, color }
}

let toastTimer: ITimer
export const showToast = (payload: IShowToastPayload, timeout = 3000) => {
  const { toast } = store.getState()
  const hideToast = () => store.dispatch(toastActions.hideToast())
  // Handle the logic if there are another toast want to show up but previous toast haven't finished yet
  if (toast.isShow) {
    hideToast()
    toastTimer?.clear()
  }

  store.dispatch(toastActions.showToast(payload))
  toastTimer = requestTimeout(hideToast, timeout)
}

export const getTaskActionText = (type: TaskType) => {
  let actionText = ''

  switch (type) {
    case 'act_follow_shop':
      actionText = i18next.t('Theo dõi')
      break
    case 'act_view_shop':
      actionText = i18next.t('Ghé thăm')
      break
    case 'act_view_item':
      actionText = i18next.t('Xem ngay')
      break
    case 'act_like_item':
      actionText = i18next.t('Thực hiện_task')
      break
    default:
      break
  }

  return actionText
}

export const executeTask = async (task: ITask) => {
  const {
    id: taskId,
    assets: { shop_id, item_id },
    action_type,
  } = task

  //* persist to show the task dialog when back from other pages
  const questionIdx = store.getState().app.currentQuestion
  updatePersistentDialog(DialogType.TaskDialog, questionIdx)

  if ((action_type === 'act_view_shop' || action_type === 'act_view_item') && task.status === 'AVAILABLE')
    await store.dispatch(
      apiSlice.endpoints.performTask.initiate({
        activityCode: router.query.cid as string,
        eventCode: store.getState().app.selectingEvent as string,
        taskId,
      }),
    )

  if (action_type === 'act_view_item' || action_type === 'act_like_item')
    micrositeNavigate(getShopeeItemUrl(shop_id, item_id))

  if (action_type === 'act_follow_shop' || action_type === 'act_view_shop') micrositeNavigate(getShopeeShopUrl(shop_id))
}

export const imageLoader = ({ src, width, quality = 75 }: ImageLoaderProps) => {
  //* for dev purpose, also a flag to turn on/off image optimized
  if (src.includes('local-hoidap') || !process.env.OPTIMIZE_IMAGE) return src

  return `https://next-image-loader-serverless-awvyuy6x5a-as.a.run.app/?url=${encodeURI(src)}&w=${width}&q=${quality}`
}

export const getCFImage = ({
  url,
  isHash = false,
  isTiny = false,
}: {
  url: string
  isHash?: boolean
  isTiny?: boolean
}) => {
  if (!url) return ''
  const topLevelDomain = getTopLevelDomain(global?.location?.hostname)
  return `${isHash ? `https://cf.shopee.${topLevelDomain}/file/${url}` : url}${isTiny ? '_tn' : ''}`
}

export let timeOffset = 0
export const setTimeOffset = (serverTime?: number) => {
  if (!serverTime) return null
  timeOffset = Date.now() - serverTime
}

export const isMobile = (): boolean => {
  try {
    if (window.innerWidth < 960) return true
  } catch (e) {
    // console.error(e)
  }

  return false
}
