import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { getRandomNumber, randomID, requestTimeout } from 'shared/utils'
import { setError } from '../store/app.slice'
import store from '../store'

interface IAxiosRetryParams {
  retries?: number
  timeWait?: number
  lastError?: AxiosError
}

export interface IAxiosBaseQueryError {
  status: AxiosResponse['status']
  data: {
    code: number
    msg: string
    data?: any
  }
}

export const appApiAxios = axios.create({
  baseURL: '',
  timeout: 60000,
  withCredentials: true,
})

appApiAxios.interceptors.request.use(
  (request) => {
    request.headers['Trace-Id'] = randomID()
    return request
  },
  (error) => {
    return Promise.reject(error)
  },
)

appApiAxios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status, data, config } = error?.response

    if (status === 500 && (config.url.includes('/activity/') || config.url.includes('/questions/')))
      store.dispatch(
        setError({
          status,
          data,
        }),
      )

    return Promise.reject(error)
  },
)

export const axiosRetry = async ({
  method = 'GET',
  url,
  data,
  retries = 5,
  timeWait = 100,
  lastError,
  ...rest
}: AxiosRequestConfig & IAxiosRetryParams) => {
  const options: AxiosRequestConfig = { method, url, data, ...rest }
  if (retries === 0) throw lastError
  try {
    return await appApiAxios(options)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (err: any) {
    if (err.response?.status === 425) {
      await new Promise((resolve) => requestTimeout(() => resolve(''), timeWait))
      return axiosRetry({
        url,
        data,
        retries: retries - 1,
        timeWait: timeWait * 2 * getRandomNumber(0.4, 1.4),
        lastError: err as AxiosError,
        method,
        ...rest,
      })
    } else throw err
  }
}

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, IAxiosBaseQueryError> =>
  async ({ url, method = 'GET', data, ...rest }) => {
    try {
      const result = await axiosRetry({ url, method, data, ...rest })
      return { data: result.data.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: { status: err.response?.status as number, data: err.response?.data },
      }
    }
  }
