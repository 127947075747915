import React from 'react'
import Script from 'next/script'

const WBLoader = () => {
  return (
    <>
      <Script
        src="https://storage.googleapis.com/shopeevn-general/shopeevn-general/scripts/new2/wb.js"
        strategy="beforeInteractive"
      />
      <Script id="wb-js">
        {`
          window.connectWebViewJavascriptBridge((bridge) => {
            bridge.init(() => {})
          })
        `}
      </Script>
    </>
  )
}

export default React.memo(WBLoader)
