import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IPersistentReminder, updatePersistentReminderList } from 'shared/persistent'

export const name = 'app'

interface intitialStateProps {
  reminderList: IPersistentReminder[]
  isLogin: boolean
  selectingEvent?: string
  showLoading?: boolean
  currentQuestion?: number
  error?: { status: number; data: { code: number } }
}

const initialState: intitialStateProps = {
  reminderList: [],
  isLogin: true,
  selectingEvent: undefined,
  error: undefined,
  currentQuestion: undefined,
  showLoading: false,
}

const homeSlice = createSlice({
  name,
  initialState,
  reducers: {
    setReminderList: (state, action: PayloadAction<IPersistentReminder[]>) => {
      state.reminderList = action.payload
    },
    addReminder: (state, action: PayloadAction<IPersistentReminder>) => {
      state.reminderList = [...state.reminderList, action.payload]

      updatePersistentReminderList(state.reminderList)
    },
    setIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setSelectingEvent: (state, action) => {
      state.selectingEvent = action.payload
    },
    setShowLoading: (state, action) => {
      state.showLoading = action.payload
    },
    setCurrentQuestion: (state, action: PayloadAction<number | undefined>) => {
      state.currentQuestion = action.payload
    },
  },
})

export const {
  actions: {
    setReminderList,
    addReminder,
    setIsLogin,
    setError,
    setSelectingEvent,
    setShowLoading,
    setCurrentQuestion,
  },
} = homeSlice

export default homeSlice.reducer
