import router from 'next/router'
import store from 'store'
import { DialogType } from 'store/dialogs.slice'

export interface IPersistentReminderList {
  [key: string]: IPersistentReminder[]
}

export interface IPersistentReminder {
  beginTime: number
  hash: number
}

const PERSISTENT_REMINDER_LIST_KEY = 'spvnQuizReminderList'
let persistentReminderList: IPersistentReminderList

export const getPersistentReminderList = (): IPersistentReminder[] => {
  const cid = router.query.cid as string
  if (!persistentReminderList) {
    persistentReminderList = JSON.parse(localStorage.getItem(PERSISTENT_REMINDER_LIST_KEY) || '{}')
    //* Remove outdated reminder Data from local Storage to avoid full of storage
    Object.keys(persistentReminderList).forEach((cid) => {
      persistentReminderList[cid].forEach((reminder, idx) => {
        if (reminder.beginTime <= Date.now()) persistentReminderList[cid].splice(idx, 1)
      })
      if (persistentReminderList[cid].length === 0) delete persistentReminderList[cid]
    })
    //* Write the filtered data to local storage
    localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
  }
  return persistentReminderList[cid] || []
}

export const updatePersistentReminderList = (reminderList: IPersistentReminder[]) => {
  const cid = router.query.cid as string
  persistentReminderList[cid] = reminderList
  localStorage.setItem(PERSISTENT_REMINDER_LIST_KEY, JSON.stringify(persistentReminderList))
}

const PERSISTENT_DIALOG_KEY = 'spvnQuizDialog'
let persistentDialog: { [key: string]: { dialog?: DialogType; questionIdx?: number } }

export const getPersistentDialog = () => {
  const selectingEvent = store.getState().app.selectingEvent as string
  if (!persistentDialog) persistentDialog = JSON.parse(localStorage.getItem(PERSISTENT_DIALOG_KEY) || '{}')
  return persistentDialog[selectingEvent]
}

export const updatePersistentDialog = (dialog?: DialogType, questionIdx?: number) => {
  const selectingEvent = store.getState().app.selectingEvent as string
  persistentDialog = { [selectingEvent]: { dialog, questionIdx } }
  localStorage.setItem(PERSISTENT_DIALOG_KEY, JSON.stringify(persistentDialog))
}
