import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import vi_VN from './vi-VN.json'
import en_SG from './en-SG.json'
import en_MY from './en-MY.json'
import th_TH from './th-TH.json'
import zh_TW from './zh-TW.json'
import pt_BR from './pt-BR.json'
import id_ID from './id-ID.json'

/**

 If you don't want non-existing keys to throw ts error you can simply do(also keeping the intellisense)
 export type ConvertedToObjectType<T> = {
  [P in keyof T]: T[P] extends string ? string : ConvertedToObjectType<T[P]>;
} & {
  [P: string]: any;
};
 */

// Selecting the json file that our intellisense would pick from
// export type TranslationJsonType = typeof import('./vi.json')

// export const trans: ConvertedToObjectType<TranslationJsonType> = {} as any

// export type ConvertedToObjectType<T> = {
//   [P in keyof T]: T[P] extends string ? string : ConvertedToObjectType<T[P]>
// }

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still having the intellisense support
 * along with type-safety
 */
// const convertLanguageJsonToObject = (json: any, objToConvertTo = trans, current?: string) => {
//   Object.keys(json).forEach((key) => {
//     const currentLookupKey = current ? `${current}.${key}` : key
//     if (typeof json[key] === 'object') {
//       objToConvertTo[key] = {}
//       convertLanguageJsonToObject(json[key], objToConvertTo[key], currentLookupKey)
//     } else {
//       objToConvertTo[key] = currentLookupKey
//     }
//   })
// }

// Create the 'translations' object to provide full intellisense support for the static json files.
// convertLanguageJsonToObject(vi)

const translationsJson = {
  'vi-VN': { translation: vi_VN },
  'en-SG': { translation: en_SG },
  'en-MY': { translation: en_MY },
  'th-TH': { translation: th_TH },
  'zh-TW': { translation: zh_TW },
  'pt-BR': { translation: pt_BR },
  'id-ID': { translation: id_ID },
}

const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // compatibilityJSON: 'v3',
    resources: translationsJson,
    lng: 'en-SG',
    fallbackLng: 'en-SG',
    debug: process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
