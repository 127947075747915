import { convertAppRL, getImage, getMicrositeUrl, getVariables, requestTimeout } from './utils'
import i18next from 'i18next'
import { ShareObjectType, ShareSNSType } from 'types'

export interface IShareResult {
  errorCode: number
  errorMessage: string
}

export interface ISharingData {
  url: string
  content: string
}

export interface IAppInfoResult {
  deviceID: string
  appDeviceID: string
  deviceFingerPrint: string
  BCAXOWidgetDeviceID: string
}

export interface IGetRecentImageResult {
  error: number
  errorMessage: string
  data: {
    thumb: string
    image: string
  }
}

// export type AppInfoResponseCallback = (AppInfoResult: IAppInfoResult) => void

export interface ISignatureData {
  from_user_id: number
  to_user_id: number
  msg_type: number
  entrypoint: number
}

export interface ICheckVersionResult {
  version: number
  appver: number | string
}

export interface INavBarOptions {
  title?: string
  showCloseIcon?: number
  hideBackButton?: number
}

export interface IConfigOptions {
  disableReload?: number
  disableBounce?: number
  disableSwipeBack?: number
}

export interface IReminderData {
  startTime: number
  endTime: number
  reminderId: number
  redirectPath: string
  title: string
  notes?: string // optional
  content?: string // optional
}

export interface IShareResult {
  errorCode: number
  errorMessage: string
}

export interface ISharingData {
  url: string
  content: string
  img: string
}

export interface IAppInfoResult {
  deviceID: string
  appDeviceID: string
  deviceFingerPrint: string
  BCAXOWidgetDeviceID: string
}

export interface IGetRecentImageResult {
  error: number
  errorMessage: string
  data: {
    thumb: string
    image: string
  }
}

// export type AppInfoResponseCallback = (AppInfoResult: IAppInfoResult) => void

export interface ISignatureData {
  from_user_id: number
  to_user_id: number
  msg_type: number
  entrypoint: number
}

export interface ICheckVersionResult {
  version: number
  appver: number | string
}

export interface INavBarOptions {
  title?: string
  showCloseIcon?: number
  hideBackButton?: number
}

export interface IConfigOptions {
  disableReload?: number
  disableBounce?: number
  disableSwipeBack?: number
}

export interface IReminderData {
  startTime: number
  endTime: number
  reminderId: number
  redirectPath: string
  title: string
  notes?: string // optional
  content?: string // optional
}

export const isShopeeApp = () =>
  typeof window !== 'undefined' && window?.navigator?.userAgent?.toLowerCase().indexOf('shopee') !== -1

export const isIOS = (): boolean => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator?.userAgent || navigator?.vendor
  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
}

export const isAndroid = (): boolean => {
  if (typeof navigator === 'undefined') return false
  const userAgent = navigator?.userAgent || navigator?.vendor
  return /android/i.test(userAgent)
}

export const isPC = () => !isIOS() && !isAndroid()

export const getAppSignature = async (signatureData: ISignatureData) =>
  new Promise<string>((resolve) => {
    const dataValue = {
      to_user_id: signatureData.to_user_id,
      from_user_id: signatureData.from_user_id,
      msg_type: signatureData.msg_type,
      entrypoint: signatureData.entrypoint,
    }
    const param = isIOS() ? { data: dataValue } : dataValue
    window.WebViewJavascriptBridge?.callHandler('getLunaManagerValue', param, (result: { data: { value: string } }) => {
      resolve(result.data.value) // The signature
    })
    setTimeout(() => {
      resolve('-')
    }, 2000)
  })

export const configurePage = (title: string, usingClose: boolean, disableReload: boolean, disableSwipeBack = true) => {
  const navbar: INavBarOptions = {}
  const config: IConfigOptions = {}

  if (title) {
    navbar.title = title
  }

  if (usingClose) {
    navbar.showCloseIcon = 1
    navbar.hideBackButton = 1
  }

  if (disableReload) {
    config.disableReload = 1
    config.disableBounce = 1
  }

  config.disableSwipeBack = disableSwipeBack ? 1 : 0

  window.WebViewJavascriptBridge?.callHandler('configurePage', { config, navbar })
}

export const getDeviceID = async () => {
  const deviceID: string = await new Promise((resolve) => {
    if (isShopeeApp())
      window.WebViewJavascriptBridge?.callHandler('getAppInfo', {}, (res: IAppInfoResult) => {
        resolve(res.deviceID || res.appDeviceID || 'DEVICEIDNOTFOUND')
      })
    else resolve('DEVICEIDNOTFOUND')
  })
  return deviceID
}

export const navigateToUrl = (url: string, navbar?: INavBarOptions) => {
  window.WebViewJavascriptBridge?.callHandler(
    'navigate',
    {
      url,
      popSelf: 1,
      navbar,
    },
    // ({ status }) => {
    //   console.log(status)
    // },
  )
}

const checkUseRnwv = () => {
  let useRnwv = false

  try {
    useRnwv = !!window.top?.ReactNativeWebView
  } catch {
    //
  }

  return useRnwv
}

export const micrositeNavigate = (url?: string) => {
  if (!url) return
  // window.top?.postMessage(JSON.stringify({ cmd: 'navigate', data: url }), '*')
  console.log('checkUseRnwv', checkUseRnwv())
  if (checkUseRnwv()) window.top?.ReactNativeWebView.postMessage(JSON.stringify({ cmd: 'navigate', data: url }))
  else window.top?.postMessage(JSON.stringify({ cmd: 'navigate', data: url }), '*')
}

export const micrositeReminder = (reminderData: IReminderData) =>
  new Promise((resolve) => {
    if (checkUseRnwv())
      window.top?.ReactNativeWebView.postMessage(JSON.stringify({ cmd: 'reminder', data: reminderData }))
    else window.top?.postMessage(JSON.stringify({ cmd: 'reminder', data: reminderData }), '*')

    // Must wait for the 500ms the reminder feature to fully triggered before do anything else
    requestTimeout(() => {
      resolve('')
    }, 500)
  })

export const showWebBridgeToast = ({
  message,
  iconType = 'success',
}: {
  message: string
  iconType: 'success' | 'failure'
}) => {
  window.WebViewJavascriptBridge?.callHandler('showToast', {
    toast: {
      iconType, // Image on toast message. Ignored by Android. Only used by iOS. Available types: success & failure
      message,
    },
  })
}

export const sharingAppHandler = (
  sharingAppID: ShareSNSType,
  objectType: ShareObjectType,
  sharingData: ISharingData,
  onSuccess: () => void = () => {},
  onFail?: (shared: boolean, appType: ShareSNSType) => void,
) => {
  const callback = ({ errorCode }: IShareResult) => {
    if (errorCode === 2) {
      showWebBridgeToast({ message: i18next.t('Ứng dụng chưa được cài đặt'), iconType: 'failure' })
      onFail?.(false, sharingAppID)
    } else if (errorCode === 0) {
      if (sharingAppID === 'copyLink') showWebBridgeToast({ message: i18next.t('Đã sao chép'), iconType: 'success' })
      if (isIOS() && sharingAppID !== 'zaloTimeline') {
        onSuccess()
      } else {
        const handleOnSuccessWithVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            onSuccess()
            document.removeEventListener('visibilitychange', handleOnSuccessWithVisibilityChange, false)
          }
        }
        document.addEventListener('visibilitychange', handleOnSuccessWithVisibilityChange, false)
      }
    }
  }
  const { url, content, img } = sharingData
  const BRIDGE_SHARING_DATA = {
    facebookLink: {
      contentUrl: url,
      quote: content,
    },
    facebookMessenger: {
      text: url,
    },
    zaloTimeline: {
      url,
    },
    copyLink: {
      link: url,
    },
    whatsapp: {
      text: url,
    },
    lineChat: {
      text: url,
    },
    telegram: {
      text: url,
    },
    twitter: {
      text: url,
    },
    instagram: {
      image: {
        imageUrl: objectType === 'event' ? getImage('mobile-download-share.png') : img,
      },
    },
  }

  window.WebViewJavascriptBridge?.callHandler(
    'shareData',
    {
      sharingAppID,
      sharingData: BRIDGE_SHARING_DATA[sharingAppID],
    },
    callback,
  )
}

export const didTapBack = (callback: () => void) => {
  window.WebViewJavascriptBridge?.registerHandler('didTapBack', callback)
}

export const micrositeLogin = (site: string, callback?: () => void) => {
  const { baseURL, shopeeLoginURL } = getVariables()
  const shopeeLoginPage = `${shopeeLoginURL}?next=${getMicrositeUrl(site, 'quiz')}`

  if (isAndroid() && !document.referrer) {
    //* Since Android Shopee App have a weird implementation on login, we must have a different flow for it
    micrositeNavigate(`${baseURL}/login?site=${site}`)
  } else {
    micrositeNavigate(shopeeLoginPage)

    //* This handle is use for iOS Shopee App, since it will only close LoginPanel
    const handleReturnFromLogin = () => {
      if (document.visibilityState === 'visible') {
        requestTimeout(() => {
          callback?.()
        }, 100)
        document.removeEventListener('visibilitychange', handleReturnFromLogin, false)
      }
    }
    document.addEventListener('visibilitychange', handleReturnFromLogin, false)
  }
}

export const webBridgeLogin = (site: string) =>
  new Promise((resolve, reject) => {
    window.WebViewJavascriptBridge?.callHandler(
      'login',
      {
        hidePopup: 1,
        redirectTab: 1,
        redirectPath: convertAppRL(getMicrositeUrl(site, 'quiz')),
      },
      (res: { status: 0 | 1 | 2 }) => {
        if (res.status === 1) {
          resolve(res)
        } else {
          reject(res)
        }
      },
    )
  })

export const popWebView = () => {
  window.WebViewJavascriptBridge?.callHandler('deviceScreenAutoLock', { isEnabled: true })
  window.WebViewJavascriptBridge?.callHandler('popWebView', {})
}
