import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from 'shared/axios-settings'
import { timeOffset } from 'shared/utils'
import {
  IAnswerResponse,
  IGetActivityResponse,
  IGetQuestionsResponse,
  IGetTasksResponse,
  IUserChanceResponse,
  ITask,
} from 'types'

// Define a service using a base URL and expected endpoints
const apiSlice = createApi({
  reducerPath: 'campaginApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Questions', 'Activity', 'Tasks', 'Chance'],
  endpoints: (builder) => {
    return {
      getActivityByCode: builder.query<IGetActivityResponse, string>({
        query: (activityCode) => ({
          url: `/quiz/activity/?activity_code=${activityCode}&limit=4&offset=0`,
        }),
        // add the offset time the follow server time
        transformResponse: (res: IGetActivityResponse) => ({
          ...res,
          slots: res.slots?.map((slot) => ({
            ...slot,
            begin_time: slot.begin_time + timeOffset,
            end_time: slot.end_time + timeOffset,
          })),
        }),
        providesTags: ['Activity'],
      }),
      getQuestions: builder.query<
        IGetQuestionsResponse,
        { eventCode: string; activityCode: string; hasUserState?: boolean }
      >({
        query: ({ eventCode, activityCode }) => ({
          url: `/quiz/questions/?activity_code=${activityCode}&event_code=${eventCode}&include_user_state=true`,
        }),
        providesTags: ['Questions'],
      }),
      answerQuestion: builder.mutation<
        IAnswerResponse,
        { eventCode: string; activityCode: string; questionId: number; optionIndex: number }
      >({
        query: ({ eventCode, activityCode, questionId, optionIndex }) => ({
          url: `/quiz/answers/`,
          data: {
            activity_code: activityCode,
            event_code: eventCode,
            question_id: questionId,
            option_index: optionIndex,
          },
          method: 'POST',
        }),
        invalidatesTags: (result, error) => {
          if (error) return []
          return ['Questions']
        },
      }),
      getTasks: builder.query<IGetTasksResponse, { activityCode: string; eventCode: string }>({
        query: ({ activityCode, eventCode }) => ({
          url: `/delta/tasks/?activity_code=${activityCode}&event_code=${eventCode}`,
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          },
        }),
        providesTags: ['Tasks'],
      }),
      performTask: builder.mutation<unknown, { activityCode: string; eventCode: string; taskId: ITask['id'] }>({
        query: ({ activityCode, eventCode, taskId }) => ({
          url: `/delta/tasks/`,
          method: 'POST',
          data: {
            activity_code: activityCode,
            event_code: eventCode,
            task_id: taskId,
          },
          headers: {
            'Gp-App-Id': process.env.APP_ID,
          },
        }),
      }),
      getUserChance: builder.query<IUserChanceResponse, { eventCode: string; activityCode: string }>({
        query: ({ eventCode, activityCode }) => ({
          url: `/quiz/user-chance/?activity_code=${activityCode}&event_code=${eventCode}`,
        }),
        providesTags: ['Chance'],
      }),
    }
  },
})

export const {
  useGetActivityByCodeQuery,
  useGetQuestionsQuery,
  useAnswerQuestionMutation,
  useGetTasksQuery,
  usePerformTaskMutation,
  useGetUserChanceQuery,
} = apiSlice

export default apiSlice
